import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PageLoading from '@/components/PageLoading';
const Index = lazy(() => import('@/pages/index/index/index.jsx'));

export default () => (
  <Router>
    <Suspense fallback={<PageLoading />}>
        <Route path="/" component={Index} />
    </Suspense>
  </Router>
)
