import React from 'react';
import { Spin } from 'antd';
import styles from './index.module.less';

const PageLoading = function() {
  return (
    <div className={styles.container}>
      <Spin />
    </div>
  )
}

export default PageLoading;
